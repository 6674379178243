export const ACCOUNT = {
    LOGIN: "/Account/login",
    FORGOT: "/Account/ForgotPassword",
    RESET: "/Account/ResetPassword",
    REFRESH_TOKEN: "/Account/refresh-token",
    FETCH_PROFILE: "/Account/Profile/Update",
    UPDATE_PROFILE: "/Account/Profile/Update",
    FETCH_CLAIMS: "/User/Claims",
    CHANGE_PASSWORD: "/Account/ChangePassword",
    LOGOUT: "Account/Logout",
    VERIFY_EMAIL: "Account/Verify",
};

export const COMPANY = {
    SIGNUP: "/Company/Signup",
    RESEND_ACTIVATION: "/Company/SendConfirmation",
    FETCH_POSITIONS: "/Company/Positions",
    FETCH_PROFILE: "/Company/Update",
    UPDATE_PROFILE: "/Company/Profile/Update",
};

export const USER = {
    FETCH_PROFILE: "",
};

export const USERS = {
    LIST: "User/List",
    ACTIVE_USERS: "User/Active/List",
    NON_ACTIVE_USERS: "User/Nonactive/List",
    CREATE: "User/Create",
    FETCH_SINGLE_USER: (userId: string): string => `/User/${userId}/Update`,
    UPDATE_SINGLE_USER: (userId: string): string => `/User/${userId}/Update`,
    UPDATE_SINGLE_USER_ROLES: (userId: string): string => `/User/${userId}/Create`,
    FETCH_CREATORS: "Report/Settings/CreateUsers",
    FETCH_REVIEWERS: "Report/Settings/ReviewUsers",
    // this is the actual endpoint to fetch creators and reviewers
    LEVELS: "Report/Settings/Level",
    DELETE_USER: (userId: string): string => `/User/${userId}/Delete`,
    UPDATE_USER_STATUS: (userId: string, status: number): string => `/User/UpdateUserStatus/${userId}/${status}`,
    SEND_CREDENTIALS_TO_USER: (id: string): string => `/User/${id}/SendCredentialsToUser`,
};

export const REPORT = {
    CREATE: "/Report/Create",
    FETCH_REPORTS: "/Report/List",
    FETCH_REPORT: (id: string): string => `/Report/${id}/details`,
    FETCH_REPORT_DETAILS: (id: string): string => `/Report/${id}/update`,
    DELETE_REPORT: (id: string): string => `/Report/${id}/Delete`,
    DETAILS: (id: string): string => `/Report/${id}/details/update`,
    UPDATE_DOCUMENTS: (id: string): string => `/Report/${id}/Documents/Update`,
    PROFILE: (id: string): string => `/Report/${id}/profile/update`,
    PRINCIPLES: (reportId: string, principleName: string): string => `/Report/${reportId}/Principle/${principleName}/Update`,
    SIGNATORIES: (id: string, reviewerId: string): string => `/Report/${id}/${reviewerId}/Signatories/update`,
    REASSIGN_SIGNATORIES: (id: string): string => `/Report/${id}/ReassignSignatory`,
    STRUCTURES: (id: string): string => `/Report/${id}/Structure/Update`,
    SUBMIT: (id: string): string => `/Report/${id}/Submit`,
    REVIEW: (id: string): string => `/Report/${id}/Review`,
    REASSIGN_REVIEWER: (id: string): string => `/Report/${id}/ReassignReviewer`,
    RENOTIFY_REVIEWER: (id: string): string => `/Report/${id}/SendForReview`,
    SIGN: (id: string): string => `/Report/${id}/SignReport`,
    SEND_TO_REGULATOR: (id: string): string => `/Report/${id}/SendToRegulator`,
    MAKE_REVIEWER: "/Report/Settings/ReviewUsers",
    MAKE_CREATOR: "/Report/Settings/CreateUsers",
    MAKE_SIGNATORY: "/Report/Settings/SignatoryUsers",
    CREATE_AUDITOR: "/Auditor/Create",
    CREATE_COMMITEE: "/Commitee/Create/Modified",
    FETCH_COMMITTEE: (id: string): string => `/Commitee/${id}/details`,
    UPDATE_COMMITTEE: (id: string): string => `/Commitee/${id}/Profile/Update/Modified`,
    FETCH_COMMITTEES: "/Commitee/List",
    FETCH_AUDITORS: "/Auditor/List",
    FETCH_AUDITOR: (id: string): string => `/Auditor/${id}/details`,
    UPDATE_AUDITOR: (id: string): string => `/Auditor/${id}/Update`,
    CREATE_CONSULTANT: "/Consultant/Create",
    UPDATE_CONSULTANT: (id: string): string => `/Consultant/${id}/Update`,
    FETCH_CONSULTANTS: "/Consultant/List",
    FETCH_CONSULTANT: (id: string): string => `/Consultant/${id}/details`,
    BACK_TO_REVIEWER: (id: string, comment: string): string => `/Report/SendToReviewer/${id}?comment=${comment}`,
    BACK_TO_CREATOR: (id: string, comment: string): string => `/Report/SendToCreator/${id}?comment=${comment}`,
    CREATE_COMMENT: (id: string): string => `/Report/${id}/comment`,
    EDIT_COMMENT: (commentId: string): string => `/Report/comment/${commentId}/edit`,
    DELETE_COMMENT: (commentId: string): string => `/Report/comment/${commentId}/delete`,
    RESOLVE_COMMENTS: "/Report/resolve-comments",
    UNRESOLVE_COMMENTS: "/Report/unresolve-comments",
    FETCH_REGULATORS: "/Regulators",
    SUBMIT_SECTORIAL_INPUTS: (id: string): string => `/Report/${id}/SectorialRequirementInputs`,
    SET_SECTORIAL_REQUIREMENT_STATUS: (reportId: string, regulatorId: string): string => `/Report/${reportId}/SetSectorialRequirementStatus?regulatorId=${regulatorId}`,
    REASSIGN_SECTORIAL_REVIEWER: (reportId: string, regulatorId: string): string => `/Report/${reportId}/ReassignSectorialReviewer?regulatorId=${regulatorId}`,
    SUBMIT_FOR_SECTORIAL_REGULATOR_REVIEW: (reportId: string, regulatorId: string): string => `/Report/${reportId}/SubmitForRegulatorySectorialReview?regulatorId=${regulatorId}`
};

export const PAYMENT = {
    GENERATERRR: `/Payment/GenerateRRR`,
    GENERATEINVOICE: (reportId: string): string => `/Report/${reportId}/GenerateInvoice`,
    QUERYTRANSACTIONUSINGRRR: (rrr: string): string => `/Report/QueryTransaction/${rrr}`,
    GENERATEANDPAYINVOICE: (reportId: string): string => `/Report/${reportId}/GenerateAndPayInvoice`,
};

export const FILE = {
    UPLOAD: "/File/Upload",
    GET: (filename: string): string => `/File/Get/${filename}`,
    DELETE: (filename: string): string => `/File/Delete/${filename}`,
};
