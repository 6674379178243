import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { REPORT } from "./endpoints";

export default class ReportService {
    static async fetchReportList(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORTS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchReportDetails(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REPORT_DETAILS(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateReportDetails({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.DETAILS(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Update Report Documents
     * @param id
     * @param body
     */
    static async updateReportDocuments({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.UPDATE_DOCUMENTS(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createReport({ templateId, regulatorId, reportingFrom, reportingTo }: { templateId: string; regulatorId: any; reportingFrom: string; reportingTo: string }): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE, {
                templateId,
                regulatorId,
                reportingFrom,
                reportingTo,
            });
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async deleteReport(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.DELETE_REPORT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async renotifyReviewer(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.RENOTIFY_REVIEWER(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createAuditor(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_AUDITOR, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Reviewer
     * @param body
     */
    static async makeReviewer(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_REVIEWER, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Creator
     * @param body
     */
    static async makeCreator(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_CREATOR, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Make User A Signatory
     * @param body
     */
    static async makeSignatory(body: Array<string>): Promise<any> {
        try {
            const data = await http.post(REPORT.MAKE_SIGNATORY, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchAuditors(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_AUDITORS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchAuditorById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_AUDITOR(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateAuditor({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.UPDATE_AUDITOR(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createConsultant(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_CONSULTANT, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateConsultant({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.UPDATE_CONSULTANT(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchConsultantById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_CONSULTANT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createCommittee(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_COMMITEE, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateCommittee({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.UPDATE_COMMITTEE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchCommitteeById(id: string): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_COMMITTEE(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchCommittees(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_COMMITTEES);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchConsultants(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_CONSULTANTS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateStakeholderDetails({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.PROFILE(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updatePrinciple({ reportId, principleName, body }: { reportId: string; principleName: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.PRINCIPLES(reportId, principleName), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateSignatories({ id, body, reviewerId }: { id: string; body: any; reviewerId: string }): Promise<any> {
        try {
            const data = await http.post(REPORT.SIGNATORIES(id, reviewerId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateStructure({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.STRUCTURES(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitReport(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reviewReport({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.REVIEW(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async signReport({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.SIGN(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async sendToRegulator(id: string): Promise<any> {
        try {
            const data = await http.post(REPORT.SEND_TO_REGULATOR(id));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reassignReviewer({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.REASSIGN_REVIEWER(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reassignSignatories({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.REASSIGN_SIGNATORIES(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async backToReviewer(id: string, comment: string): Promise<any> {
        try {
            const data = await http.put(REPORT.BACK_TO_REVIEWER(id, comment));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async backToCreator(id: string, comment: string): Promise<any> {
        try {
            const data = await http.put(REPORT.BACK_TO_CREATOR(id, comment));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async createComment({ id, body }: { id: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.CREATE_COMMENT(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async editComment({ commentId, body }: { commentId: string; body: any }): Promise<any> {
        try {
            const data = await http.post(REPORT.EDIT_COMMENT(commentId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async deleteComment(commentId: string): Promise<any> {
        try {
            const data = await http.post(REPORT.DELETE_COMMENT(commentId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async resolveComments(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.RESOLVE_COMMENTS, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async unresolveComments(body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.UNRESOLVE_COMMENTS, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchRegulators(): Promise<any> {
        try {
            const data = await http.get(REPORT.FETCH_REGULATORS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitSectorialRequirementInputs(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT_SECTORIAL_INPUTS(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async setSectorialRequirementStatus(payload: any, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.SET_SECTORIAL_REQUIREMENT_STATUS(payload.reportId, payload.regulatorId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async reassignSectorialReviewer(payload: any, body: any): Promise<any> {
        try {
            const data = await http.post(REPORT.REASSIGN_SECTORIAL_REVIEWER(payload.reportId, payload.regulatorId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async submitForSectorialRegulatorReview(payload: any): Promise<any> {
        try {
            const data = await http.post(REPORT.SUBMIT_FOR_SECTORIAL_REGULATOR_REVIEW(payload.reportId, payload.regulatorId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
