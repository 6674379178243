/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { ACCOUNT, COMPANY } from "./endpoints";

export default class AuthService {
    static async loginUser(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.LOGIN, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async signUpUser(payload: any): Promise<any> {
        try {
            const data = await http.post(COMPANY.SIGNUP, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async verifyEmail(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.VERIFY_EMAIL, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async resendActivation(payload: any): Promise<any> {
        try {
            const data = await http.post(COMPANY.RESEND_ACTIVATION, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async forgotPassword(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.FORGOT, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async resetPassword(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.RESET, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async logout(): Promise<any> {
        const data = await http.post(ACCOUNT.LOGOUT);
        return data;
    }

    static async fetchUserProfile(): Promise<any> {
        try {
            const data = await http.get(ACCOUNT.FETCH_PROFILE);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchClaims(): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.FETCH_CLAIMS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async updateUserProfile(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.UPDATE_PROFILE, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async changePassword(payload: any): Promise<any> {
        try {
            const data = await http.post(ACCOUNT.CHANGE_PASSWORD, payload);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static authenticateUser(tokens: { access: string }): void {
        if (tokens.access) {
            http.defaults.headers.common.Authorization = `Bearer ${tokens.access}`;
            localStorage.setItem("authToken", tokens.access);
        }
    }
}
