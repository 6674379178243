/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import http from "@/utils/http";
import resolveRequestError from "@/utils/errorHandler";
import { USERS } from "./endpoints";

export default class UserService {
    static async createUser(body: any): Promise<any> {
        try {
            const data = await http.post(USERS.CREATE, body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * fetch active users
     */
    static async fetchActiveUsers(): Promise<any> {
        try {
            const data = await http.get(USERS.ACTIVE_USERS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * fetch inactive active users
     */
    static async fetchNonActiveUsers(): Promise<any> {
        try {
            const data = await http.get(USERS.NON_ACTIVE_USERS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchUsers(): Promise<any> {
        try {
            const data = await http.get(USERS.LIST);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async fetchLevels(): Promise<any> {
        try {
            const data = await http.get(USERS.LEVELS);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    static async sendCredentialsToUser(id: string, body: any): Promise<any> {
        try {
            const data = await http.post(USERS.SEND_CREDENTIALS_TO_USER(id), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Delete Single User
     * @param userId
     * @param status
     */
    static async updateUserStatus(userId: string, status: number): Promise<any> {
        try {
            const data = await http.put(USERS.UPDATE_USER_STATUS(userId, status));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * UPDATE USER STATUS
     * @param userId
     */
    static async deleteSingleUser(userId: string): Promise<any> {
        try {
            const data = await http.post(USERS.DELETE_USER(userId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * Fetch Single User
     * @param userId
     */
    static async fetchSingleUser(userId: string): Promise<any> {
        try {
            const data = await http.get(USERS.FETCH_SINGLE_USER(userId));
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * User Single User
     * @param userId
     * @param body
     */
    static async updateSingleUser({ userId, body }: { userId: string; body: any }): Promise<any> {
        try {
            const data = await http.post(USERS.UPDATE_SINGLE_USER(userId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }

    /**
     * User Single User Roles
     * @param userId
     * @param body
     */
    static async updateSingleUserRoles({ userId, body }: { userId: string; body: Array<string> }): Promise<any> {
        try {
            const data = await http.post(USERS.UPDATE_SINGLE_USER_ROLES(userId), body);
            return data;
        } catch (error) {
            return resolveRequestError(error);
        }
    }
}
